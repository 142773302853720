<template>
  <header>
    <div>
      <div class="header-content pad-h-3 flex gap-h-1">
        <img src="@/assets/header-detail.svg" />
        <h1 class="app-title flex-0" v-html="$t('views.home.title')"></h1>
      </div>
    </div>
  </header>
</template>
