<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useAspectRatioLock } from "@/composables/onWindowResize";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import FullscreenLayout from "@/layouts/FullscreenLayout.vue";
import routerModalState from "@/components/router-modal/routerModalState";

const { lockScale, iPadFullscreenMode } = useAspectRatioLock();
const route = useRoute();

const routeClass = computed(() => {
  if (route.path === "/") {
    return "route-home";
  }
  const path = route.path;
  let className = "route" + path.replaceAll("/", "-");
  if (path.startsWith("/home/about")) {
    className += " about";
  }
  return className;
});
</script>

<template>
  <div
    :class="[
      'app-wrapper',
      routeClass,
      iPadFullscreenMode && 'fullscreen-mode',
      routerModalState.showModal && 'modal-opened',
    ]"
  >
    <FullscreenLayout v-if="iPadFullscreenMode" :scale="lockScale" />
    <DefaultLayout v-else :scale="lockScale" />
  </div>
</template>
