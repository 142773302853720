import { createRouter, createWebHashHistory } from "vue-router";
import pageTitleConfig from "./pageTitleConfig.json";

const routes = () => [
  {
    path: "/",
    redirect: () => {
      return { path: "/home" };
    },
  },
  {
    path: "/home",
    name: "Home page",
    component: () => import("../views/home/HomeView.vue"),
    children: [
      {
        path: "about",
        name: "about",
        component: () => import("../views/home/about/AboutView.vue"),
        props: true,
        meta: {
          showModal: true,
          parentPath: "/home",
        },
        children: [
          {
            path: "etiology",
            name: "About_etiology",
            component: () =>
              import("../views/home/about/tabContents/EtiologyView.vue"),
          },
          {
            path: "genetics",
            name: "About_genetics",
            component: () =>
              import("../views/home/about/tabContents/GeneticsView.vue"),
          },
          {
            path: "diagnosis",
            name: "About_diagnosis",
            component: () =>
              import("../views/home/about/tabContents/DiagnosisView.vue"),
          },
          {
            path: "symptoms",
            name: "About_symptoms",
            component: () =>
              import("../views/home/about/tabContents/SymptomsView.vue"),
          },
          {
            path: "testing",
            name: "About_testing",
            component: () =>
              import("../views/home/about/tabContents/TestingView.vue"),
          },
          {
            path: "monitoring",
            name: "About_monitoring",
            component: () =>
              import("../views/home/about/tabContents/MonitoringView.vue"),
          },
        ],
      },
      {
        path: "references",
        name: "References",
        component: () => import("../views/home/references/ReferencesView.vue"),
        meta: {
          showModal: true,
          parentPath: "/home",
        },
      },
    ],
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("../views/explore/ExploreView.vue"),
    children: [
      {
        path: "",
        redirect: "/section1/subsection0",
      },
      {
        path: "symptoms",
        name: "Signs and symptoms page",
        component: () => import("../views/explore/symptoms/SymptomsView.vue"),
        children: [
          {
            path: ":symptomName",
            name: "symptom-details",
            component: () =>
              import("../views/explore/symptoms/DetailedView.vue"),
            props: true,
            meta: {
              showModal: true,
              parentPath: "/explore/symptoms",
            },
          },
          {
            path: "symptom-legend",
            name: "symptom-legend",
            component: () =>
              import(
                "../views/explore/symptoms/symptom-legend/SymptomLegendView.vue"
              ),
            meta: {
              showModal: true,
              parentPath: "/home",
            },
          },
        ],
      },
      {
        path: "challenges",
        name: "challenges",
        component: () =>
          import("../views/explore/challenges/ChallengesView.vue"),
      },
      {
        path: "patients",
        name: "Patient case studies",
        component: () => import("../views/explore/patients/PatientsView.vue"),
        children: [
          {
            name: "patient-view",
            path: ":patientId",
            component: () =>
              import("../views/explore/patients/profiles/PatientProfile.vue"),
            props: true,
            meta: {
              showModal: true,
              parentPath: "/explore/patients",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: () => {
      return { path: "/home" };
    },
  },
];

const wrappedRouter = () => {
  const router = createRouter({
    history: createWebHashHistory(),
    routes: routes(),
  });
  return router;
};

function pageTracker(to) {
  let pageTitle = to.name;
  if (pageTitle === "symptom-details") {
    pageTitle = `Signs and symptoms_${
      pageTitleConfig.symptomNames[to.params.symptomName]
    }`;
  }
  if (pageTitle === "patient-view") {
    pageTitle = `Patient case studies_patient ${to.params.patientId}`;
  }
  return {
    page_title: pageTitle,
    page_path: to.path,
  };
}

export { wrappedRouter, pageTracker };
