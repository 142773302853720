export default {
  props: {
    navDepth: {
      type: Number,
      default: 0,
    },
    navData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentItem: undefined,
      selectedIndex: 0,
    };
  },
  methods: {
    itemClick(e, shouldReplace = false) {
      this.currentItem = e.itemData;
      this.navigate(this.currentItem, shouldReplace);
    },
    navigate(dataItem, shouldReplace = false) {
      if (dataItem?.path !== undefined) {
        if (shouldReplace) {
          this.$router.replace({
            path: dataItem.path,
          });
        } else {
          this.$router.push({
            path: dataItem.path,
          });
        }
      }
    },
    getCurrentItem(ignoreNavigate) {
      var pathSplit = this.$route.path.split("/");
      var path = "/" + pathSplit.slice(1, 2 + this.navDepth).join("/");
      var result = this.navData.filter((item) => item.path == path);
      var item = result?.[result?.length - 1];
      if (item) {
        item.ignoreNavigate = ignoreNavigate;
      }
      return result?.[result?.length - 1];
    },
  },
  watch: {
    $route() {
      this.currentItem = this.getCurrentItem(true);
    },
    currentItem: function (newItem) {
      if (newItem) {
        this.selectedIndex = newItem.id;
      }
    },
  },
  mounted() {
    this.currentItem = this.getCurrentItem(true);
  },
};
