<template>
  <nav class="link-menu">
    <DxButton
      v-for="item in navData"
      :key="item.id"
      :text="$t(item.textKey)"
      @click="itemClick({ itemData: item })"
      :type="item == currentItem ? 'default' : 'normal'"
      :icon="item.icon ?? null"
      :class="item?.classList"
    >
    </DxButton>
  </nav>
</template>

<script>
import DxButton from "devextreme-vue/button";
import NavMenu from "@/mixins/NavMenu.js";

export default {
  name: "LinkMenu",
  components: {
    DxButton,
  },
  mixins: [NavMenu],
};
</script>
