<template>
  <DxTabs
    :data-source="navData"
    v-model:selected-index="selectedIndex"
    @itemClick="(e) => itemClick(e, true)"
  >
    <template #item="{ data }">
      {{ $t(data.textKey) }}
    </template>
  </DxTabs>
</template>

<script>
import DxTabs from "devextreme-vue/tabs";
import NavMenu from "@/mixins/NavMenu.js";

export default {
  name: "TabNav",
  components: {
    DxTabs,
  },
  mixins: [NavMenu],
};
</script>
