import Dexie from "dexie";
import videoPathsConfig from "@/components/load-video/video-paths-config";

export const db = new Dexie("offlineVideos");
db.version(1).stores({
  videos: "++id, key", // Primary key and indexed props
});

function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const loadVideos = () => {
  Object.keys(videoPathsConfig).forEach(async (key) => {
    const video = await db.videos.where("key").equals(key).first();
    if (!video?.data) {
      const blob = await fetch(videoPathsConfig[key]).then((r) => r.blob());
      const data = await blobToBase64(blob);
      if (data?.startsWith("data:video")) {
        await db.videos.add({
          data,
          key,
        });
      } else {
        console.error(
          `Video in this path(${videoPathsConfig[key]}) was not found`
        );
      }
    }
  });
};
