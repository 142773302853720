import { ref, onMounted, onUnmounted, computed } from "vue";

export const useAspectRatioLock = (width = 1366, height = 1024) => {
  const lockScale = ref(1);
  const isPotrait = ref(false);

  const update = () => {
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;
    if (winWidth < winHeight) {
      isPotrait.value = true;
    } else {
      isPotrait.value = false;
    }
    // compare window aspect ratio to container's 16:9
    if (winWidth / winHeight < width / height) {
      // scale by width
      lockScale.value = winWidth / width;
    } else {
      // scale by height
      lockScale.value = winHeight / height;
    }
    lockScale.value = Math.round(lockScale.value * 1000) / 1000;
  };

  // This will check if device is an iPad and fullscreen mode is needed or not
  const iPadFullscreenMode = computed(() => {
    if (isPotrait.value) {
      return false;
    }
    const isTouchCapable =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
    if (
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(ip|ap|wp))))/.test(
        navigator.userAgent.toLocaleLowerCase()
      ) ||
      (/macintosh/.test(navigator.userAgent.toLocaleLowerCase()) &&
        isTouchCapable)
    ) {
      return true;
    }
    return false;
  });

  onMounted(() => {
    update();
    window.addEventListener("resize", update);
  });
  onUnmounted(() => window.removeEventListener("resize", update));

  // expose managed state as return value
  return { lockScale, isPotrait, iPadFullscreenMode };
};
