import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueGtag from "vue-gtag";
import Particles from "particles.vue3";

import App from "./App.vue";
import { wrappedRouter, pageTracker } from "./router";
import locales from "./locales";
import { loadVideos } from "./db";

import "./assets/custom-icons/css/custom-icons.css";
import "./scss/global.scss";
import "./registerServiceWorker";

function startup() {
  const defaultLocale = "en"; // !NOTE: hard coded as english for now may change in future
  const router = wrappedRouter();
  const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages: locales,
  });
  createApp(App)
    .use(i18n)
    .use(router)
    .use(Particles)
    .use(
      VueGtag,
      {
        pageTrackerTemplate: pageTracker,
        config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
      },
      router
    )
    .mount("#root");
}

loadVideos();

startup();
